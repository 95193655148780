<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">

    <discount-coupons-form-popup :discountCouponPopupActive.sync="sendOfferToCustomerPromptActive" :currentDiscountCoupon="currentDiscountCoupon" :isEditDiscountCoupon="isEditDiscountCoupon"/>

    <div class="container-fluid my-4 px-4">
        <div class="vx-row justify-end">
            <vs-button icon="add" @click="addDiscountCoupon">{{ $t("Add")}}</vs-button>
        </div>
    </div>
    <vs-table 
        class="mb-5"
        :sst="true" 
        ref="table"
        @change-page="handleChangePage"
        v-model="selected"
        pagination
        :total="parseInt(tableConfig.total)"
        :max-items="parseInt(tableConfig.perPage)" 
        :data="discountCoupons"
        @selected="editDiscountCoupon"
        >

        <template  slot="thead">
            <template>
                <vs-th v-for="(col, index) in generalInfoColumnsTitles" :key="index" :sort-key="col.sortKey">
                <p class="table-head font-medium truncate">{{ col.title }}</p>
                </vs-th>
            </template>
        </template>
        

        
        <template slot-scope="{data}">
            <tbody>
            <template>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p class="product-name font-medium truncate">{{tr.name}}</p>
                  </vs-td>
                  <vs-td>
                    <p class="booking-date truncate">{{ tr.code}}</p>
                  </vs-td>
                  <vs-td>
                    <p class="booking-date truncate">{{ tr.discount}} {{tr.discount_type == 'percentage' ? '%' : $t('SAR')}}</p>
                  </vs-td>
                  <vs-td>
                    <vs-chip color="success" v-if="tr.start_date || tr.end_date">{{$t('discountCouponsList.yes')}}</vs-chip>
                    <vs-chip color="warning" v-else>{{$t('discountCouponsList.no')}}</vs-chip>
                  </vs-td>
                  <vs-td>
                    <vs-chip color="success" v-if="tr.is_valid == 'valid'">{{$t('discountCouponsList.valid')}}</vs-chip>
                    <vs-chip color="warning" v-else>{{$t('discountCouponsList.inValid')}}</vs-chip>
                  </vs-td>
                  <vs-td class="whitespace-no-wrap">
                    <span class="flex flex-row whitespace-no-wrap">
                      <vs-button class="mx-1 action-btn" type="border" icon-pack="feather" icon="icon-trash" @click.stop="deleteDiscountCouponDialog(tr)"  color="danger"></vs-button>
                    </span>
                  </vs-td>
                  
                  
                </vs-tr>
            </template>
            
            </tbody>
        </template>
        
        </vs-table>
    
  </div>
</template>

<script>

import axios from '@/axios'
import DiscountCouponsFormPopup from '@/views/components/qosoor-components/DiscountCoupons/DiscountCouponsFormPopup.vue'
export default {
  components: {
    DiscountCouponsFormPopup
  },
  data () {
    return {
        selected: [],
        generalInfoColumnsTitles : [        
            {title: this.$t('discountCouponsList.name'), key: "name"},
            {title: this.$t('discountCouponsList.code'), key: "code"},
            {title: this.$t('discountCouponsList.discount'), key: "discount"},
            {title: this.$t('discountCouponsList.dateRange'), key: "startDate"},
            {title: this.$t('discountCouponsList.isValid'), key: "isValid"},
            {title: '', key: "actions"},
        ],
        tableConfig: {
            total: 0,
            perPage: 10
        },
        discountCoupons: [],
        currentBooking: null,
        sendOfferToCustomerPromptActive: false,
        currentDiscountCoupon: null,
        isEditDiscountCoupon: false
    }
  },
  computed: {
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    
  },
  methods: {
    getDiscountCoupons(page=1) {
      let query = {
        page: page,
        perPage: this.tableConfig.perPage
      }
      axios.get(`/venues/${this.currentVenueId}/discountCoupons`, {params: query})
        .then((response) => {
            this.discountCoupons = response.data.discountCoupons.data
            this.tableConfig.total = response.data.total
        })
        .catch((error) => {})
        .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);
    },
    handleChangePage(page) {
      this.getDiscountCoupons(page);
    },
    editDiscountCoupon(data) {
      this.currentDiscountCoupon = data;
      this.isEditDiscountCoupon = true;
      this.sendOfferToCustomerPromptActive = true;
    },
    addDiscountCoupon() {
        this.currentDiscountCoupon = {
            discount_type: 'fixed'
        };
        this.isEditDiscountCoupon = false;
        this.sendOfferToCustomerPromptActive = true;
    },
    deleteDiscountCouponDialog(discountCoupon) {
      this.$vs.dialog({
        color: 'danger',
        title: this.$t('discountCouponsList.deleteCouponHeader') + discountCoupon.name,
        text: this.$t('discountCouponsList.deleteCouponConfirmation'),
        acceptText: this.$t('discountCouponsList.yes'),
        accept: this.deleteDiscountCoupon,
        parameters: [discountCoupon]
      })
    },
    deleteDiscountCoupon: function(discountCoupon) {
      this.$store.dispatch('loader/loaderOn')
      axios.delete(`/venues/${this.currentVenueId}/discountCoupons/${discountCoupon[0].id}`)
        .then((response) => {
          this.getDiscountCoupons();
          this.$vs.notify({
            color:'success',
            text: response.data.success_message || 'تم الحفظ بنجاح'
          })
        })
        .catch((error) => {})
        .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);
    }
    
  },
  created () {
    this.getDiscountCoupons();
  }
}
</script>

<style lang="scss">

</style>
